import React, { FC } from 'react';
import { useTranslation, useEnvironment } from '@wix/yoshi-flow-editor';
import Camera from 'wix-ui-icons-common/on-stage/Camera';
import { Avatar } from 'wix-ui-tpa/Avatar';
import { ImageService } from '@wix/members-area-commons-ts';
import { MediaPlatformImage } from '@wix/members-domain-ts';

import {
  ElementId,
  InjectedClassNameProps,
  InjectedDataHookProps,
  Nullable,
  ProfileImage,
  ProfileImageSize,
  ProfileLayout,
} from '../../../types';
import styles from './AvatarInput.scss';
import classNames from 'classnames';
import { getElementId } from '../../../services/html-attributes';

type AvatarProps = {
  url: Nullable<string | MediaPlatformImage>;
  imageType: ProfileImage;
  avatarSize?: ProfileImageSize;
  profileLayout?: ProfileLayout;
  canUpload?: boolean;
  onClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  name?: string;
} & InjectedDataHookProps &
  InjectedClassNameProps;

const AvatarInput: FC<AvatarProps> = ({
  url,
  imageType,
  className = '',
  dataHook,
  name,
  onClick,
  canUpload,
  avatarSize,
  profileLayout,
}) => {
  const { t } = useTranslation();
  const { widgetId } = useEnvironment();
  const inputId = getElementId(widgetId, ElementId.ProfilePhotoInput);

  if (imageType === ProfileImage.None) {
    return null;
  }

  const sizeClasses = {
    [styles.small]: avatarSize === ProfileImageSize.Small,
    [styles.large]: avatarSize === ProfileImageSize.Large,
  };

  const wrapperClasses = {
    [styles.wrapper]: true,
    [styles.fullWidth]: profileLayout === ProfileLayout.FullWidth,
    [styles.card]: profileLayout === ProfileLayout.Card,
  };

  const avatarClasses = {
    [styles.avatar]: true,
    [styles.squareAvatar]: imageType === ProfileImage.Square,
  };

  return (
    <div className={classNames(sizeClasses)}>
      <div className={classNames(wrapperClasses, className)}>
        <Avatar
          name={name || ''}
          src={url ? new ImageService().getImageUrl({ image: url }) : undefined}
          data-hook={dataHook}
          className={classNames(avatarClasses)}
        />
        {canUpload && (
          <div>
            <input
              id={inputId}
              className={styles.uploadInput}
              type="file"
              accept="image/*"
              aria-label={t('profile-widget.change-profile-photo')}
              onChange={onClick}
            />
            <label htmlFor={inputId} className={styles.uploadLabel} />
            <div className={styles.pictureWrapper}>
              <div className={styles.iconOval}>
                <Camera />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AvatarInput;
